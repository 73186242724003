// @ts-check
// / <reference path="../../../typings/brand.d.ts" />
// eslint-disable-next-line no-unused-vars
import { GenericColors } from '../../styles/colors';

/** @type BrandConfig */
export const config = {
  entranceId: 'ent_apollo',
  auth0: {
    domain: 'relymd-patient-test.auth0.com',
    clientId: 'SG1bgUia38ERw1mOG6r3KJkz82bvlhdt',
    logoutRedirectUrl: '',
    realm: 'Username-Password-Authentication',
  },
  serverUrl: 'https://dev-api-dot-bison-233014.appspot.com/',
  stripeKey: 'pk_test_xASftwBMb3miJrkFUQXEg9GV00adG7MUyN',
  googlePlacesApiKey: 'AIzaSyC1CWPTIoX6OLfW_YhmAoPj3dmYS-o5de0',
  appName: 'RelyMD',
  privacyPolicyUrl: 'https://static.relymd.app/legal/relymd/privacy.html',
  termsUrl: 'https://static.relymd.app/legal/relymd/eula.html',
  defaultPharmacySearchLatLng: [35.78, 78.64],
  serverOutageUrl: 'https://static.relymd.app/dev/status.json',
  iosAppUrl: 'https://apps.apple.com/us/app/relymd/id962442972',
  androidAppUrl: 'https://play.google.com/store/apps/details?id=com.virtumedix.relymd&hl=en',
  theme: {
    primary: {
      main: '#6199B2',
      dark: '#334960',
      error: '#EF0E0E',
      success: '#63C888',
      warning: '#F1C40F',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#06006D',
    },
  },
};
